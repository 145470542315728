<template>
  <div>
    <b-modal :id="`modal-anexos${ficha?'-ficha':''}`" size="md">
      <template #modal-title>
        <i class="fas fa-paperclip"></i> Adicionar/Editar Anexos
      </template>
      <AnexosForm
        @salvar="salvar($event)"
        @salvo="onSalvo($event)"
        @excluir="excluir($event)"
        @close="closeModal"
        :anexoEdit="anexoEdit"
        :loading="loadingForm"
        ref="AnexoForm"
        :tipo="tipo"
        :salvarDireto="salvarDireto"
      />

      <template #modal-footer>
        <b-button
          v-if="typeof anexoEdit.index === 'number'"
          variant="secondary"
          class="align-left"
          @click="$refs.AnexoForm.excluir()"
          ><i class="fas fa-trash"></i> Excluir</b-button
        >
    
        <b-button variant="primary" class="" @click="salvarDireto?$refs.AnexoForm.salvarDireto():$refs.AnexoForm.salvar()"
          ><i class="fas fa-save"></i> Salvar</b-button
        >
        <b-button
          variant="warning"
          class=""
          @click="closeModal"
          ><i class="fas fa-ban"></i> Cancelar</b-button
        >
      </template>
    </b-modal>

    <AnexosTable
      :anexos="anexos"
      @editar="editar($event)"
      :loading="loadingTable"
      @excluir="excluir($event)"
    />
  </div>
</template>

<script>
import AnexosTable from "./AnexosTable.vue";
import AnexosForm from "./AnexosForm.vue";
// import FilesLib from "../../libs/FilesLib";
// import AnexosLib from "../../libs/AnexosLib";

export default {
  name:"PacienteAnexos",
  props: {
    anexos: { type: Array, default: () => [] },
    tipo: String,
    salvarDireto: {type: Boolean, default: false},
    ficha: {type: Boolean, default: false}
  },
  components: {
    AnexosTable,
    AnexosForm,
  },
  data() {
    return {
      // anexos: [],
      anexoEdit: {},

      loadingForm: false,
      loadingTable: false,
    };
  },
  methods: {
    async editar(id) {
      this.$forceUpdate();
      await this.$nextTick()
      // alert(this.anexos[id].titulo  )

      if (typeof id == "number") {
        Object.assign(this.anexoEdit, { ...this.anexos[id], index: id });
      } else {
        this.anexoEdit = {};
      }

      this.$bvModal.show(`modal-anexos${this.ficha?'-ficha':''}`);
    },
    closeModal(){
      this.$bvModal.hide(`modal-anexos${this.ficha?'-ficha':''}`);
    },
    // async carregar() {
    //   await this.$nextTick();
    //   if (this.tipo && this.relacionamentoId) {
    //     this.loadingTable = true;
    //     try {
    //       this.anexos = await AnexosLib.get(this.tipo, this.relacionamentoId);
    //       console.log(this.anexos);
    //     } catch (error) {
    //       console.log(`Erro ao carregar anexos. ${error}`);
    //       this.$swal.fire({
    //         toast: true,
    //         title: "Ops! Erro ao carregar anexos!",
    //         icon: "error",
    //         position: "top-end",
    //         timer: 1500,
    //         showConfirmButton: false,
    //         timerProgressBar: true,
    //       });
    //     }
    //         this.loadingTable = false;
    //   }

    //   this.$forceUpdate();
    // },
    async salvar(anexo) {
      this.$bvModal.hide(`modal-anexos${this.ficha?'-ficha':''}`);
      this.$forceUpdate();
      await this.$nextTick();
      const { index } = anexo;
      delete anexo.index;
      if (anexo && typeof index != "undefined" && index.toString() != "") {
        // this.anexos[index] = anexo
        this.$set(this.anexos, index, anexo);
      } else {
        this.anexos.push(anexo);
      }
    },
    async onSalvo(anexo){
      // console.log('chamou onsalvo');
      await this.salvar(anexo)
      this.$emit('salvo')
    },
    onExcluir() {
      this.$refs.AnexoForm.excluir();
    },
    async excluir(id) {
      if (id.toString() != "") {
        this.$swal
          .fire({
            title: "Você tem certeza que deseja remover este anexo?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim. Excluir!",
            cancelButtonText: "Não.",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.$bvModal.hide(`modal-anexos${this.ficha?'-ficha':''}`);
              if (
                this.anexos[id]._rowStatus &&
                this.anexos[id]._rowStatus === "added"
              ) {
                this.anexos.splice(id, 1);
              } else {
                this.$set(this.anexos[id], "_rowStatus", "deleted");
                this.$emit("excluir")
              }
            }
          });
      }
    },
  },
  async mounted() {
    // await this.carregar();
  },
};
</script>

<style>
</style>