<template>
  <div>

    <div class="card-container">
      <div
        class="card"
        v-for="anexo in anexosFiltrados"
        :key="anexo.id"
        @click="$emit('editar', anexos.indexOf(anexo))"
      >

        <img class="rounded"  :src="formataUrl(anexo.url, anexo.tipo_arquivo)" :alt="`Anexo_${anexo.id}`" />
        <!-- <i
          v-else-if="anexo.url.indexOf('pdf') >= 0"
          class="fas fa-file-pdf"
        ></i> -->
        <div class="card-content">
          <div class="card-title">{{ anexo.titulo }} </div>
          <div class="card-type">
            <strong>Tipo</strong>
            <p>{{ anexo.tipo_arquivo }}</p>
          </div>
          <div class="card-date">
           Referente à  {{ anexo.data_referencia | moment("DD/MM/YYYY") }} e criado em
            {{ anexo.created_at | moment("DD/MM/YYYY") }} às
            {{ anexo.created_at | moment("HH:mm") }} por {{anexo.usuario}}
          </div>

          <div class="card-size">
            <strong>Tamanho</strong>
            <p>150 kb</p>
          </div>
          
        </div>
      </div>
    </div>
    <div class="row d-flex align-items-baseline">
      <div class="col-12 d-flex justify-content-end">
        <b-button variant="primary" @click="novo()">
          <span class="fas fa-plus"></span> Anexar
        </b-button>
      </div>
    </div>

    
  </div>
</template>

<script>
import "@/assets/scss/cards_anexo.scss";
import FilesLib from "../../libs/FilesLib";


export default {
  components:{
    
  },
  props: {
    anexos: { type: Array, required: true, default: () => [] },
    loading: Boolean,
  },
  data() {
    return {
      token: this.$store.state.auth.user.token,
    };
  },
  methods: {
    novo() {
      this.$emit("editar");
    },
    formataUrl(url, tipo) {
      // let url
      // this.$forceUpdate();
      if (tipo && tipo.indexOf('pdf') >= 0) {
        return   require("@/assets/img/pdf.png") 
      }
      if (tipo && tipo.indexOf('text') >= 0) {
        return   require("@/assets/img/txt.png") 
      }
      if (tipo && tipo.indexOf('video') >= 0) {
        return   require("@/assets/img/video.png") 
      }

      if (url && url.indexOf("blob") >= 0) {
        return url ||
        require("@/assets/img/no-file.png") ;
      }

      return (
        FilesLib.downlaodFile(url, this.token) ||
        require("@/assets/img/no-file.png")
      );
    },
    // teste(url) {
    //   return URL.createObjectURL(url);
    // },
  },
  async mounted() {
    this.$forceUpdate();
    await this.$nextTick();
    // if (this.anexos.length > 0) {
    //   this.anexos.map(anexo=>{
    //     this.anexos.push(Object.assign({}, anexo))
    //   })
    // }
  },

  computed: {
    anexosFiltrados() {
      return this.anexos.filter((anexo) => {
        // console.log(anexo);

        if (anexo._rowStatus && anexo._rowStatus === "deleted") {
          return false;
        }
        return true;
      });
    },
  },
};
</script>
